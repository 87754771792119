<template>
    <div class="aksk page-container" >
        <el-breadcrumb class="crumb" separator="/">
            <el-breadcrumb-item>认证管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="aksk-page">
            <div class="aksk-opts">
              <div>
                <OptButton size="small" type="primary"  class="_create"
                           v-if="accountType === 'main' && regionType !== 'GDAS'"
                           :disabled="createDisable" @click="createAKSK">创建
                </OptButton>
              <OptButton size="small" icon="icon iconfont iconbianzu1711" style="margin-left:8px;color:#949494;transform: translateY(2px);min-width: 32px;width: 32px!important;padding: 6px 7px!important;" @click="loadData(true)"></OptButton>
              </div>

            </div>
            <el-table :data="tableData" v-loading="loadingData" style="width: 100%">
                <el-table-column prop="regionName" label="资源池" width="240"></el-table-column>
                <el-table-column prop="s3Host" label="endpoint"></el-table-column>
                <el-table-column prop="accessKey" label="AccessKey"></el-table-column>
                <el-table-column prop="secretKey" label="SecretKey"></el-table-column>
                <el-table-column prop="create" label="创建时间" width="300">
                    <template slot-scope="{row}">{{ row.create && moment(row.create).format('YYYY-MM-DD HH:mm:ss') }}</template>
                </el-table-column>
                <el-table-column label="操作" width="60" v-if="accountType === 'main' && regionType !== 'GDAS'">
                    <template slot-scope="{ $index, row }">
                        <OptLink :underline="false" class="op-link" v-if="row.status == 'building'" disabled>创建中
                        </OptLink>
                        <OptLink
                            :style="IS_SSC ? 'color: #FA5050;' : ''"
                            :underline="false"
                            class="op-link" v-else-if="row.storagePlatformType==='XSKY' || row.storagePlatformType==='E37DEG' || row.storagePlatformType==='SUPERSTOR'" :disabled="handleStatus(row)"
                                 @click="confirmDeleteOne($index, row)">删除
                        </OptLink>

                    </template>
                </el-table-column>
            </el-table>
            <Pager class="bs-pager" v-model="pager" :sizes="[5, 10, 20, 50, 100]"
                   @currentChange="handleCurrentChange" @sizeChange="handleSizeChange"></Pager>
            <el-dialog  title="创建认证" custom-class="changeConfirm" :visible.sync="showCreate" style="margin-top: 15vh;"
                        :close-on-click-modal="false" width="484px">
                <el-form ref="form" label-width="80px" :model="model">
                    <el-form-item label="资源池:" prop="regionId2" >

                        <el-select v-model="CRegionId" :disabled="true" placeholder="请选择资源池" size="332px" style="width: 332px;" no-data-text="无可用资源池，请购买资源包">
                            <el-option  v-for="item in regionList.slice(1).filter(i => i.userStatus==='NORMAL')" :key="item.regionId" :label="setLabel(item, true)" :value="item.regionId" />
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                     <el-button size="small" @click="showCreate=false" >取 消</el-button>
                  <el-button size="small" type="primary" v-loading="formLoading" @click="doCreate">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import store from '../store'
import moment from 'moment'
import {getRegionList} from "@/utils/session";
import {mapGetters} from 'vuex'
import storage from "@/utils/storage";
export default {
    name: "AKSK",
    data: () => {
        return {
            regionType: '',
            regionList: [
            ],
            regionId: '',
            CRegionId: '', //  当前全局的资源池
            loadingData: false,
            tableData: [],
            totalData: [],
            pager: {
                current: 1,
                size: 10,
                total: 0,
            },
            creating: false,
            showCreate: false,
            currentAkSk: {},
            timer: null,
            createDisable: false,
            actives: 0,
            model: {
                regionId2: ''
            },
            formLoading: false
        };
    },
    computed: {
        ...mapGetters(['accountType', 'points'])
    },
    created() {
        this.regionType = localStorage.getItem('regionType')
        // this.timer = setInterval(this.silenceLoadData, 1000);
        this.loadData(true);
        this.getRegionList();
        this.CRegionId = storage.get('REGION_ID')
    },
    methods: {
        moment,
        setLabel ({regionName, storagePlatformType, regionId, userStatus}, isCreate)  {
            if (regionId === '') {
                return  regionName
            }
            return `${userStatus==='SUSPENDED' ? '【已冻结】' : ''}${regionName}`
        },
        // 非XSKY更换aksk
        handleReplace (index, {regionId}) {
            this.$postJson('/aksk/replace', {regionId})
            .then(res => {
                if (res.code === '200') {
                    this.$message.success('替换成功')
                    this.loadData(true);
                }
            })
        },
        getRegionList () {
            this.$post('/region/listPurchased', {})
                .then(res => {
                    if (res.code === '200') {
                        this.regionList = [{regionId: '', regionName: '已开通资源池'}].concat(res.data || []);
                    }
                })
        },
        wcRegionChange (id) {
            this.regionId = id;
            this.loadData();
        },
        loadData(isInit) {
            this.loadingData = true;
            const {
                pager: {current, size},
                regionId
            } = this;
            if (isInit) {
                return this.$postJson("/aksk/list", {regionId}).then((res) => {
                    const {
                        data = [],
                    } = res;
                    this.totalData = data;
                    if (data.records) {
                        this.totalData = data.records;
                    }
                    this.handlePager(this.totalData);
                    this.loadingData = false;
                }).catch(err => this.loadingData = false);
            } else {
                this.handlePager()
                this.loadingData = false;
            }

        },
        // 同一区域 如果只有一个 ak/sk ，删除按钮置灰
        handleStatus ({regionId}) {
            let list = getRegionList() || [];
            let {totalData} = this;
            return totalData.filter(i => i.regionId === regionId).length === 1
        },
        handlePager () {
            const { pager: {current, size}, regionId, totalData } = this;
            let list = [];
            if (!regionId) {
                list = totalData
            } else {
                list = totalData.filter(i => i.regionId === regionId)
            }
            this.pager.total = list.length
            this.tableData = list.slice(current * size - size, current * size);
        },
        handleSizeChange(size) {
            this.pager.current = 1;
            this.pager.size = size;
            this.loadData();
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            this.loadData();
        },
        createAKSK() {
            this.showCreate = true;
            this.creating = true;
        },
        createDialogClose() {
            this.showCreate = false;
            this.currentAkSk = {};
            this.loadData();
        },
        closeDialog() {
            this.showCreate = false;
        },
        doCreate() {
            if (this.formLoading) {
                return
            }
            this.$refs.form.validate(valid => {
                if (valid) {
                    const {totalData} = this;
                    let regionId2 = this.CRegionId
                    if (totalData.filter(i => i.regionId === regionId2).length >= 2) {
                        this.$message.warning("认证信息已满，不能再添加！");
                    } else {
                        this.createDisable = true;
                        this.loadingData = true;
                        //Cannot read property 'then' of undefined
                        this.formLoading = true
                        this.$postJson("/aksk/create", {regionId: regionId2})
                            .then((res) => {
                                // this.timer = setInterval(this.silenceLoadData, 1000);
                                this.showCreate = false;
                                this.loadData(true).then(() => {
                                    this.$message.success("创建AK/SK成功");
                                    this.createDisable = false;
                                });
                            })
                            .catch((e) => {
                                this.createDisable = false;
                                this.loadingData = false;
                                this.$message.error(e.message);
                            })
                        .finally(() => {
                            this.formLoading = false
                        })
                    }
                }
            })
        },
        copyAccessKey() {
            const {currentAkSk} = this;
            this.$copyText(currentAkSk.accessKey).then(
                (res) => {
                    this.$message.success("复制AK成功");
                },
                (err) => {
                    this.$message.error("复制失败，请重试");
                }
            );
        },
        copySecretKey() {
            const {currentAkSk} = this;
            this.$copyText(currentAkSk.secretKey).then(
                (res) => {
                    this.$message.success("复制SK成功");
                },
                (err) => {
                    this.$message.error("复制失败，请重试");
                }
            );
        },
        confirmDeleteOne(index, row) {
            const {tableData} = this;
            if (tableData.length <= index) {
                this.$message.error("删除失败");
                return;
            }
            this.$confirm("确定删除AK/SK吗?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                showClose: false,
            })
                .then(() => {
                    this.deleteOne(index, row);
                })
                .catch(() => {
                    console.log("取消删除");
                });
        },
        deleteOne(index, row) {
            const {tableData} = this;
            const {id} = tableData[index];
            this.$postJson("/aksk/delete", {akskId: id,regionId: row.regionId})
                .then((res) => {
                    // store.dispatch('user/checkUserInfo');
                    this.$message.success("删除AK/SK成功");
                    this.loadData(true);
                })
                .catch((e) => {
                    this.$message.error(e.message || "删除AK/SK失败");
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.aksk {
    margin: 16px 16px 10px;
    padding: 0 20px;
    background: #fff;
    text-align: left;
  min-height: calc(100% - 36px);

    ::v-deep.changeConfirm {
        .el-dialog__header {
            //display: none;
            border: none;
            padding: 30px 30px 0;

            .el-dialog__title {
                font-size: 16px;
                color: rgba(0, 0, 0, .85);
                font-weight: 500;
            }

            .el-dialog__headerbtn {
                top: 30px;
                right: 30px;
            }
        }

        .el-dialog__body {
            padding: 30px 30px 0;
        }

        .el-form-item__label {
            font-size: 14px;
            color: #000;
            font-weight: 400;
        }
    }
}

.aksk-opts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0 22px;
}

::v-deep.aksk .el-breadcrumb__inner {
    font-size: 16px;
}
</style>
